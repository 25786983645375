import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';

import { isLoggedIn, selectAuthState } from '@libs/auth-lib';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  selectAuthState$ = this.store.select(selectAuthState);
  isLoggedIn$ = this.store.select(isLoggedIn);
  constructor(private router: Router, private store: Store) {
  }

  canActivate(): Observable<boolean> {
    return this.isLoggedIn$.pipe(
      switchMap((loggedIn) => {
        if (!loggedIn) {
          this.router.navigate(['/auth']);
          return of(false);
        }
        return of(true);
      })
    );
  }

}
